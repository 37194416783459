body, html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%);
  color: #333333;
  position: relative;
}

.App-header {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Suppression de l'animation de rotation du logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Style pour le texte */
p {
  font-size: calc(12px + 2vmin);
  font-weight: bold;
}

.sub-text {
  font-size: calc(8px + 2vmin);
  font-weight: normal;
  margin-top: 10px;
}

/* Style pour les cercles */
.circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  animation: move 20s infinite;
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(50vw, 50vh);
  }
  100% {
    transform: translate(0, 0);
  }
}